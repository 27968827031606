import React from "react"
import BirthdayCredit from "../../components/body/pages/incentive-program/birthdayCredit"
import NoheaderFooter from "../../components/Noheaderfooter"
import SEO from "../../components/seo"

const BirthdayCreditRoute = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/incentive-program/birthday-credit"}
      title="Kuda Incentive Program"
    />
    <BirthdayCredit />
  </NoheaderFooter>
)
export default BirthdayCreditRoute
